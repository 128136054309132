/**
Add a Stimulus controller for this component.
It will automatically registered and its name will be available
via component-name

You have to export export class Controller extends BaseController 
to use index.js as a stimulus controller. If you need to use a 
stimulus controller by naming the conventional way 
(i.e. the file ends in _controller.js) you need to export default 
like the following so that the controller is properly registered:

import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller { 
  ... 
} 
 */

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = [
    'registeredTagId',
    'dateValueForm',
    'dateValueField',
    'numberValueForm',
    'numberValueField',
    'stringValueForm',
    'stringValueField'
  ];

  connect() {
    this.addRegisteredTagIdHooks();
    this.showValueForms();
  }

  disconnect() {
    this.removeRegisteredTagIdHooks();
  }

  addRegisteredTagIdHooks() {
    if (this.registeredTagIdTarget) {
      this.registeredTagIdTarget.addEventListener('change', () => {
        this.showValueForms();
      });
    }
  }

  removeRegisteredTagIdHooks() {
    if (this.registeredTagIdTarget) {
      this.registeredTagIdTarget.removeEventListener('change', () => {
        this.showValueForms();
      });
    }
  }

  showValueForms() {
    const valueType = this.setValueType();

    if (valueType == 'Date') {
      this.showDateValueField();
      this.hideNumberValueField();
      this.hideStringValueField();
    } else if (valueType == 'String') {
      this.showStringValueField();
      this.hideNumberValueField();
      this.hideDateValueField();
    } else if (valueType == 'Number') {
      this.showNumberValueField();
      this.hideDateValueField();
      this.hideStringValueField();
    } else {
      this.hideNumberValueField();
      this.hideDateValueField();
      this.hideStringValueField();
    }
  }

  showDateValueField() {
    if (this.dateValueFormTarget) {
      this.dateValueFormTarget.classList.remove('hidden-field');
      DatePicker.init();
    }
  }

  hideDateValueField() {
    if (this.dateValueFormTarget) {
      this.dateValueFormTarget.classList.add('hidden-field');
    }
    if (this.dateValueFieldTarget) {
      this.dateValueFieldTarget.value = null;
    }
  }

  showStringValueField() {
    if (this.stringValueFormTarget) {
      this.stringValueFormTarget.classList.remove('hidden-field');
    }
  }

  hideStringValueField() {
    if (this.stringValueFormTarget) {
      this.stringValueFormTarget.classList.add('hidden-field');
    }
    if (this.stringValueFieldTarget) {
      this.stringValueFieldTarget.value = null;
    }
  }

  showNumberValueField() {
    if (this.numberValueFormTarget) {
      this.numberValueFormTarget.classList.remove('hidden-field');
    }
  }

  hideNumberValueField() {
    if (this.numberValueFormTarget) {
      this.numberValueFormTarget.classList.add('hidden-field');
    }
    if (this.numberValueFieldTarget) {
      this.numberValueFieldTarget.value = null;
    }
  }

  setValueType() {
    if (this.registeredTagIdTarget) {
      return $(this.registeredTagIdTarget).find('option:selected').data('value-type')
    }
  }
}
